import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ServicioModuloService {

  modelo: any
  papa: any;
  hijo: any;
  nombreElementoActual: any;
  nombreSubSistemaActual: any;
  componenteTab: any;
  modeloWizard: any;
  modeloWizardTemp: any;
  tabSeleccionado: any;
  codigoInforme: any;
  codigoPermiso: any;
  componenteActual: any;
  codigoPuntoCaptacion: any = "";
  codigoPuntoDescarga: any = "";
  codigoPredioCaptacion: any = "";
  codigoCaracterizacion: any = "";
  codigoResolucion: any = "";
  codigoOcupacion: any = "";
  codigoExploracion: any = "";
  codigoEmision: any = "";
  tipoFuente: any = "";
  codigoDatoProduccion: any = "";
  cleanModel: Boolean = false;
  codigoPSMV: any = "";
  codigoParaNoti: any = "";
  tipoParaNoti: any = "";
  numeroParaNoti: any = "";
  tipoRegistro: any = "";
  fechaRegistro: any = "";
  procedeRecurso: any = "";
  codigoVisita: any = "";
  tipoLiquididacion: any = "";
  valorLiquididacion: any = "";
  jsonLiquidacion: any = "";
  per_estado: any = '';
  per_tipo: any = '';
  dfo_codigo: any = "";
  especie_codigo: any = "";
  rod_codigo: any = "";
  pln_nummat: any = "";
  apr_noapr: any = "";
  inf_tipreg: any = "";
  scu_unihid: any = "";
  poc_nptocu: any = 0;
  pro_codigo: string = "";
  vis_tipapr: string = "";
  vis_numppe: any = 0;
  ppe_numero: string = "";
  constructor() { }

  /**
* Recibe un modelo
* @author Andrés Quintero
* @date 2019-02-08.
* @param
* @returns
*/
  RecibirModelo(objeto) {
    this.modelo = objeto;
    this.ObtenerModelo();
  }

  /**
  * Se envia un modelo al componente que se haya registrado como hijo
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  ObtenerModelo() {
    if (this.hijo) {
      this.hijo.AsignarModelo(this.modelo);
    }
  }

  /**
  * Se envia un modelo al componente que se haya registrado como hijo
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  ActualizarModelo(modelo) {
    if (this.papa) {
      this.papa.ActualizarModelo(modelo);
    }
  }


  /**
  * Se envia un modelo al componente que se haya registrado como hijo
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  ReiniciarTab() {
    if (this.papa) {
      this.papa.ReiniciarTabNotificaciones();
    }
  }

  /**
  * Se envia un modelo recibido desde un hijo al componente padre(contenedor)
  * @author Andrés Quintero
  * @date 2019-02-08.
  * @param
  * @returns
  */
  EnviarModeloAccion(modelo) {
    if (this.papa) {
      this.papa.RealizarAccion(modelo);
    }
  }

  /**
   * Se reinicia el model que tenga el hijo
   * @author Victor Bedoya
   * @date 2019-02-19.
   * @param
   * @returns
   */
  ObtenerModeloInicial() {
    if (this.hijo) {
      this.hijo.ObtenerModelo();
    }
  }


  /**
   * Recibe el valor del subsistema seleccionado
   * @author Esteban Muñoz
   * @date 2019-03-26.
   * @param
   * @returns
   */
  SetSubSistema(valor) {
    this.nombreSubSistemaActual = valor;
  }

  /**
  * Envia al hijo el valor del subsistema seleccionado
  * @author Esteban Muñoz
  * @date 2019-03-26.
  * @param
  * @returns
  */
  ObtenerSubSistema() {
    if (this.hijo) {
      this.hijo.SetSubSistema();
    }
  }

  /**
  * Envia al hijo el valor del subsistema seleccionado
  * @author Andrés Quintero
  * @date 2019-03-26.
  * @param
  * @returns
  */
  ActualizarFormularioTab() {
    if (this.componenteTab) {
      this.componenteTab.ActualizarFormulario();
    }
  }

  /**
   * Habilita el tab requerido
   * @author Brayan Rios
   * @date 2019-11-29
   */
  HabilitarFormularioTab() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarTab();
    }
  }

  EnableTabFotos() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarTabFotos();
    }
  }

  /**
   * Habilita el tab usos de agua si hay mas de un predio por punto de captación
   * @author Brayan Rios
   * @date 2019-12-02
   */
  desbloquearTab(opcion) {
    this.componenteTab.HabilitarCantidad(opcion);
  }

  /**
   * Deshabilita los tabs al cambiar de elemento de la lista
   * @author Brayan Rios
   * @date 2019-12-02
   */
  ActualizarTabs() {
    if (this.componenteTab) {
      this.componenteTab.actualizarTabs();
    }
  }

  /**
  * Envia al hijo el valor del subsistema seleccionado
  * @author Esteban Muñoz
  * @date 2019-08-21.
  * @param
  * @returns
  */

  AsignarValorFiltroCajetin(valor) {
    if (valor == undefined || valor == null || valor == '') {
      this.hijo.ObtenerInformacionTarjetas(undefined);
    } else {
      this.hijo.ObtenerInformacionTarjetas(valor);
    }

  }


  /**
     * Se envia un modelo al componente que se haya registrado como hijo
     * @author Juan David cardona
     * @date 2019-08-15.
     * @param
     * @returns
     */
  asignarModeloLista(modelo) {
    this.modelo = modelo;
    if (this.hijo && modelo) {
      this.hijo.asignarModeloLista(modelo);
    }
  }

  /**
     * Se envia un modelo al componente que se haya registrado como hijo
     * @author Juan David cardona
     * @date 2019-08-15.
     * @param
     * @returns
     */
  AsignarTabSeleccionado(tab) {
    this.tabSeleccionado = tab;

  }


  /**
     * Se envia un modelo al componente que se haya registrado como hijo
     * @author Juan David cardona
     * @date 2019-08-15.
     * @param
     * @returns
     */
  ObtenerTabSeleccionado() {
    return this.tabSeleccionado;

  }


  /**
   * Muestra el carga masiva de caracterización
   * @author Brayan Rios
   * @date 2019-12-17
   * @param
   * @returns
   */
  abrirCargaMasiva(tipo, valor) {
    this.papa.ObtenerPlantillas(tipo, valor);
  }

  /**
   * Oculta la carga masiva cuando el componente de caracterización cambie
   * @author Brayan Rios
   * @date 2019-12-17
   * @params
   * @returns
   */
  ocultarCargaMasiva() {
    this.papa.OcultarCargaMasiva();
  }



  /**
 * Deshabilita los tabs al cambiar de elemento de la lista
 * @author Brayan Rios
 * @date 2019-12-02
 */
  BuscarFormulario(tipo) {
    if (this.componenteTab) {
      this.componenteTab.buscarActo(tipo);
    }
  }

  HabilitarFotosAprovechamiento() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarFotosAprovechamiento();
    }
  }

  DeshabilitarFotosAprovechamiento() {
    if (this.componenteTab) {
      this.componenteTab.DeshabilitarFotosAprovechamiento();
    }
  }

  HabilitarTabSolicitudInfor() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarTabSolicitudInfor();
    }
  }

  DeshabilitarTabSolicitudInfor() {
    if (this.componenteTab) {
      this.componenteTab.DeshabilitarTabSolicitudInfor();
    }
  }

  HabilitarTabVisitaTecnica() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarTabVisitaTecnica();
    }
  }

  DeshabilitarTabVisitaTecnica() {
    if (this.componenteTab) {
      this.componenteTab.DeshabilitarTabVisitaTecnica();
    }
  }

  HabilitarTabRegistroFot() {
    if (this.componenteTab) {
      this.componenteTab.HabilitarTabRegistroFot();
    }
  }

  DeshabilitarTabRegistroFot() {
    if (this.componenteTab) {
      this.componenteTab.DeshabilitarTabRegistroFot();
    }
  }

  HabilitarDesTabEspeciesSolicitadas(tipo) {
    if (this.componenteTab) {
      this.componenteTab.HabilitarDesTabEspeciesSolicitadas(tipo);
    }
  }

  HabilitarDesEspeciesSolicitadas(tipo) {
    if (this.componenteTab) {
      this.componenteTab.HabilitarDesEspeciesSolicitadas(tipo);
    }
  }

  HabilitarPuntosVertimiento(tipo) {
    if (this.componenteTab) {
      this.componenteTab.HabilitarPuntosVertimiento(tipo);
    }
  }

  HabilitarRequerimientosAnteriores(habilitar) {
    if (this.componenteTab) {
      this.componenteTab.HabilitarRequerimientosAnteriores(habilitar);
    }
  }

  HabilitarRequerimientosPosteriores(habilitar) {
    if (this.componenteTab) {
      this.componenteTab.HabilitarRequerimientosPosteriores(habilitar);
    }
  }

  HabilitarFotoOcupacion(habilitar) {
    if (this.componenteTab) {
      if (habilitar == 'SI') {
        this.componenteTab.showTab7 = true;
      } else {
        this.componenteTab.showTab7 = false;
      }

    }
  }

}
